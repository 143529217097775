function LoginButton() {
  return (
    <div>
      <button type="button" className="w-20 h-8 bg-green-700 text-white rounded-md hover:bg-green-900">
        Login
      </button>
    </div>
  );
}

export default LoginButton;
