import { useHistory, useParams } from "react-router-dom";
import useFetch from "./useFetch";

const ActivityDetails = () => {

  const { id } = useParams();
  console.log(id);
  const { data: activity } = useFetch('http://localhost:8000/activities/' + id);

    return (
        <div className="activity-details">

          <h2>activity Details</h2>
          {
            activity && (
              <h2>{ activity.activityName }</h2>
            )
          }
        
        </div>
    );
}
export default ActivityDetails;