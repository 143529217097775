function Copyright() {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <div className="bg-red-500 text-center font-medium text-white">
      <address>
        &copy; Copyright Kids Activity - All Right Reserved {currentYear}
      </address>
      <p>
        Notes: All data are collected through public API on the Internet. Images
        are selected from{" "}
        <a className="underline " href="https://unsplash.com/">
          Unsplash
        </a>
      </p>
    </div>
  );
}

export default Copyright;
