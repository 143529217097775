import SearchIcon from "../images/search.svg";

function Search() {
  return (
    <div className="invisible lg:visible lg:w-full ">
      <form>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-red-500 dark:text-red-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            // className="block text-left w-full p-4 ps-10 text-sm text-red-900 border border-red-200 rounded-lg bg-gray-50 ring-5 focus:ring-white-500 focus:border-red-500"
            className="block  text-left w-full p-3 ps-10 outline-none ring-2 ring-red-700 ring-offset-2 rounded-lg focus:bg-gray-50 focus:text-white"
            placeholder="Search Activities"
            required
          />
          <button
            type="submit"
            class="w-1/5  text-white absolute end-2.5 bottom-2.5 bg-red-500 hover:bg-red-800 focus:ring-2 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Search
          </button>
        </div>
      </form>
    </div>

    // <div className=" flex">
    //   <input
    //     className="m-3 p-5 w-64 bg-red-500 placeholder-gray-500 placeholder-opacity-100 text-white h-10 rounded-lg"
    //     placeholder="Search for activties"
    //     value=""
    //     type="search"
    //   />
    //   <img
    //     className="h-auto w-8 transition-transform duration-300 hover:scale-110"
    //     src={SearchIcon}
    //     alt="search"
    //   />
    // </div>
  );
}

export default Search;
