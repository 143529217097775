function FilterIcon(props) {
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        className="w-10 h-10 transition-transform duration-300 hover:scale-110"
        src={props.iconUrl}
        alt="filterIcon"
      />
      <p className="text-center ">{props.name}</p>
    </div>
  );
}

export default FilterIcon;
