function Avatar(props) {
  return (
    <img
      className="circle-img justify-self-end"
      src={props.imgUrl}
      alt="avatarImg"
    />
  );
}

export default Avatar;
