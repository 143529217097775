import Rating from "./Rating";
function ActivityCard(props) {
  return (
    <div className=" m-1 gap-1 max-w-full ">
      <div className="m-1  ">
        <img
          className="object-fill h-64 w-full transition-transform duration-300 hover:scale-105 "
          src={props.imgSrc}
          alt="cardImg"
        />
      </div>
      <div className="m-1 grid grid-cols-2 gap-1 max-w-full">
        <div className="w-full">
          <p className="text-lg font-bold">{props.activityName}</p>
          <p>Location: {props.location}</p>
          <p>Date: {props.date}</p>
          <p>Fees:$ {props.fees}</p>
        </div>
        <div className="w-full flex justify-end ">
          <Rating src={props.starSrc} ratingScore={props.ratingScore} />
        </div>
      </div>
    </div>

    // <div className="activityCard">
    //     <div className="cardTop">
    //     <img className="cardImg" src={props.imgSrc} alt="cardImg"/>
    //     </div>
    //     <div className="cardBottom">
    //     <div className="cardBottomLeft">
    //         <p className="activityName">{props.activityName}</p>
    //         <p>Location: {props.location}</p>
    //         <p>Date: {props.date}</p>
    //         <p>Fees:$ {props.fees}</p>
    //     </div>
    //     <div className="cardBottomRight">
    //         <Rating src={props.starSrc} ratingScore ={props.ratingScore}/>
    //     </div>

    //     </div>
    // </div>
  );
}

export default ActivityCard;
