const activities=[
    {   id:"1",
        activityName:"swimming",
        ratingScore:4.8,
        date:"Oct 28, 2023",
        location:"OTCC",
        fees:2.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {   id:"2",
        activityName:"Halloween",
        ratingScore:5.0,
        date:"Oct 28, 2023",
        location:"Kids Farm",
        fees:5.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }, 
    {   id:"3",
        activityName:"swimming",
        ratingScore:4.8,
        date:"Oct 28, 2023",
        location:"OTCC",
        fees:2.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
    {   id:4,
        activityName:"Halloween",
        ratingScore:5.0,
        date:"Oct 28, 2023",
        location:"Kids Farm",
        fees:5.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {   id:"1",
        activityName:"swimming",
        ratingScore:4.8,
        date:"Oct 28, 2023",
        location:"OTCC",
        fees:2.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {   id:"2",
        activityName:"Halloween",
        ratingScore:5.0,
        date:"Oct 28, 2023",
        location:"Kids Farm",
        fees:5.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }, 
    {   id:"3",
        activityName:"swimming",
        ratingScore:4.8,
        date:"Oct 28, 2023",
        location:"OTCC",
        fees:2.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
    {   id:4,
        activityName:"Halloween",
        ratingScore:5.0,
        date:"Oct 28, 2023",
        location:"Kids Farm",
        fees:5.0,
        imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },{   id:"1",
    activityName:"swimming",
    ratingScore:4.8,
    date:"Oct 28, 2023",
    location:"OTCC",
    fees:2.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
},
{   id:"2",
    activityName:"Halloween",
    ratingScore:5.0,
    date:"Oct 28, 2023",
    location:"Kids Farm",
    fees:5.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
}, 
{   id:"3",
    activityName:"swimming",
    ratingScore:4.8,
    date:"Oct 28, 2023",
    location:"OTCC",
    fees:2.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
{   id:4,
    activityName:"Halloween",
    ratingScore:5.0,
    date:"Oct 28, 2023",
    location:"Kids Farm",
    fees:5.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
},
{   id:"1",
    activityName:"swimming",
    ratingScore:4.8,
    date:"Oct 28, 2023",
    location:"OTCC",
    fees:2.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
},
{   id:"2",
    activityName:"Halloween",
    ratingScore:5.0,
    date:"Oct 28, 2023",
    location:"Kids Farm",
    fees:5.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
}, 
{   id:"3",
    activityName:"swimming",
    ratingScore:4.8,
    date:"Oct 28, 2023",
    location:"OTCC",
    fees:2.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1661481416961-712751a62cd7?auto=format&fit=crop&q=80&w=1880&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
{   id:4,
    activityName:"Halloween",
    ratingScore:5.0,
    date:"Oct 28, 2023",
    location:"Kids Farm",
    fees:5.0,
    imgSrc:"https://plus.unsplash.com/premium_photo-1669682926607-7ffcddea117a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
}
    
];

export default activities;