import star from "../images/star.png";

function Rating(props) {
  return (
    <div className="flex justify-end w-1/5 space-x-1">
      <img className="h-5 w-5" src={star} alt="ratingStar" />
      <p className="">{props.ratingScore}</p>
    </div>
  );
}

export default Rating;
