import "../static/styles/main.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ActivityDetails from "./ActivityDetails";
import Home from "./Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/activities/:id" element={<ActivityDetails />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
