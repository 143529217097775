// the images folder should be in the public folder

const filterIcons = [
  {
    id: 1,
    name: "Sports",
    iconUrl: "/images/stadium.png",
  },
  {
    id: 2,
    name: "Theme",
    iconUrl: "/images/amusement-park.png",
  },
  {
    id: 3,
    name: "Gym",
    iconUrl: "/images/gym.png",
  },
  {
    id: 4,
    name: "Zoo",
    iconUrl: "/images/zoo.png",
  },
  {
    id: 5,
    name: "Farm",
    iconUrl: "/images/barn.png",
  },
  {
    id: 6,
    name: "Park",
    iconUrl: "/images/park.png",
  },
];

export default filterIcons;
