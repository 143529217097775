import Logo from "./Logo";
import AppHeading from "./AppHeading";
import Search from "./Search";
import Avatar from "./Avatar";
import ActivityCard from "./ActivityCard";
import Copyright from "./Copyright";
import panda from "..//images/panda.png";
import SampleActivity from "./SampleActivity";
import Filters from "./FilterIcons";
import FilterIcon from "./FilterIcon";
import LoginButton from "./LoginButton";

const Home = () => {
  return (
    <div className="">
      <div className="grid grid-cols-3 gap-5 items-center">
        <div className="grid grid-cols-2 items-center gap-3">
          <Logo />
          <AppHeading />
        </div>
        <div className="flex">
          <Search />
        </div>
        <div className="flex justify-end items-center gap-5">
          <LoginButton />
          <Avatar imgUrl={panda} />
        </div>
      </div>
      <hr />

      <div className="">
        <div className=" m-2 flex justify-center flex-row gap-10 items-center">
          {Filters.map((filter) => (
            <FilterIcon
              key={filter.id}
              name={filter.name}
              iconUrl={filter.iconUrl}
            />
          ))}
        </div>
        <hr />
        <div className="">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
            {SampleActivity.map((activity) => (
              <ActivityCard
                key={activity.id}
                activityName={activity.activityName}
                imgSrc={activity.imgSrc}
                location={activity.location}
                date={activity.date}
                fees={activity.fees}
                ratingScore={activity.ratingScore}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="">
        <Copyright />
      </div>
    </div>
  );
};
export default Home;
